import { computed } from '@angular/core';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';

import { PolicyOwner } from '../policy-details/policy-details.types';
import { Group, GroupBase, PoliciesState } from './policies.types';

const policiesInitialState: PoliciesState = {
	groups: {},
	groupsList: {
		customer: [],
		public: [],
	},
	owners: {},
	selectedGroupId: '',
};

export const PoliciesStore = signalStore(
	{ providedIn: 'root' },
	withState(policiesInitialState),
	withComputed((store) => ({
		allGroups: computed(() => Object.values(store.groups() || {})),
		allGroupsNames: computed(() => Object.values(store.groups() || {}).map((g) => g.name)),
		customerGroups: computed(
			() => (store.groupsList().customer || []).map((id) => store.groups()[id]) as GroupBase[],
		),
		publicGroups: computed(
			() => (store.groupsList().public || []).map((id) => store.groups()[id]) as GroupBase[],
		),
		ownerOptions: computed(() => store.owners()[store.selectedGroupId()] || []),
		selectedGroup: computed(() => store.groups()[store.selectedGroupId()] as Group | undefined),
	})),
	withMethods((store) => ({
		addCustomerGroup(group: GroupBase) {
			patchState(store, (state) => {
				const groupDetails = state.groups[group.id] || {};

				return {
					...state,
					groups: { ...state.groups, [group.id]: { ...groupDetails, ...group } },
					groupsList: {
						...state.groupsList,
						customer: [...new Set([...state.groupsList.customer, group.id])],
					},
				};
			});
		},
		addGroupDetails(group: Group) {
			patchState(store, (state) => {
				const groupDetails = state.groups[group.id] || {};

				return {
					...state,
					groups: { ...state.groups, [group.id]: { ...groupDetails, ...group } },
				};
			});
		},
		addPublicGroup(group: GroupBase) {
			patchState(store, (state) => {
				const groupDetails = state.groups[group.id] || {};

				return {
					...state,
					groups: { ...state.groups, [group.id]: { ...groupDetails, ...group } },
					groupsList: {
						...state.groupsList,
						public: [...new Set([...state.groupsList.public, group.id])],
					},
				};
			});
		},
		addOwners(groupId: string, owners: PolicyOwner[]) {
			patchState(store, (state) => ({
				...state,
				owners: { ...state.owners, [groupId]: owners },
			}));
		},
		removeCustomerGroup(groupId: string) {
			patchState(store, (state) => {
				const groups = { ...state.groups };
				delete groups[groupId];

				return {
					...state,
					groups,
					groupsList: {
						...state.groupsList,
						customer: state.groupsList.customer.filter((id) => id !== groupId),
					},
				};
			});
		},
		removePublicGroup(groupId: string) {
			patchState(store, (state) => {
				const groups = { ...state.groups };
				delete groups[groupId];

				return {
					...state,
					groups,
					groupsList: {
						...state.groupsList,
						public: state.groupsList.public.filter((id) => id !== groupId),
					},
				};
			});
		},
		resetState() {
			patchState(store, policiesInitialState);
		},
		updateGroupDetails(groupId: string, group: Partial<Group | GroupBase>) {
			patchState(store, (state) => ({
				...state,
				groups: { ...state.groups, [groupId]: { ...state.groups[groupId], ...group } },
			}));
		},
		updateSelectedGruopId(id: string) {
			patchState(store, (state) => ({
				...state,
				selectedGroupId: id,
			}));
		},
	})),
);
