import { inject, Injectable, signal } from '@angular/core';
import { ApiService } from '@core/api/api.service';
import { AndroidAppConfig } from '@root/app/shared/android-apps.types';
import { AndroidSetting } from '@root/app/shared/android-settings.types';
import { finalize, map, tap } from 'rxjs';

import { PoliciesStore } from './policies.store';
import { Group, GroupBase, PoliciesByCustomer } from './policies.types';

export type DeviceNewAndroidPolicy = Pick<AndroidSetting, 'settingId' | 'value'>;

export interface DeviceNewAppPolicy
	extends Pick<AndroidAppConfig, 'appId' | 'status' | 'internetConfig'> {
	packageName: string;
}

export interface GroupNew extends Pick<Group, 'churchCode' | 'name'> {
	customUser?: string;
	policy: {
		policyAndroidSettings: DeviceNewAndroidPolicy[];
		policyAppJoins: DeviceNewAppPolicy[];
	};
}

@Injectable({
	providedIn: 'root',
})
export class PoliciesService {
	readonly #policiesStore = inject(PoliciesStore);
	apiService = inject(ApiService);

	isLoading = signal<boolean>(false);

	addPolicy(data: GroupNew) {
		return this.apiService.post<string>('/android/groups', data);
	}

	deletePolicy(id: string) {
		return this.apiService.delete(`/android/groups/${id}`);
	}

	getByCustomer(customerCode: string, customerId?: string) {
		this.isLoading.set(true);

		return this.apiService.get<GroupBase[]>(`/android/groups/getbycustomer/${customerCode}`).pipe(
			finalize(() => {
				this.isLoading.set(false);
			}),
			map((response): PoliciesByCustomer => {
				if (!customerId) {
					return { ownerPolicies: [], allPolicies: response };
				}

				const customCustomer = [customerCode, customerId];
				const ownerPolicies = response
					.filter((policy) => policy?.customUser && customCustomer.includes(policy?.customUser))
					.map((policy) => ({ ...policy, owner: customerCode }));
				const allPolicies = response
					.filter((policy) => !policy?.customUser || !customCustomer.includes(policy?.customUser))
					.map((policy) => ({
						...policy,
						owner: customCustomer.includes(policy?.customUser || '') ? 'Custom' : '',
					}));

				if (response.length > 0 && !(allPolicies.length || ownerPolicies.length)) {
					return { ownerPolicies: [], allPolicies: response };
				}

				return { ownerPolicies, allPolicies };
			}),
			tap((data: PoliciesByCustomer) => {
				data.ownerPolicies.forEach((policy) => this.#policiesStore.addCustomerGroup(policy));
				data.allPolicies.forEach((policy) => this.#policiesStore.addPublicGroup(policy));
			}),
		);
	}

	getPublicPolicies() {
		this.isLoading.set(true);

		return this.apiService.get<GroupBase[]>('/android/groups/getpublic').pipe(
			finalize(() => {
				this.isLoading.set(false);
			}),
			map((response): PoliciesByCustomer => {
				return { ownerPolicies: [], allPolicies: response };
			}),
			tap((data: PoliciesByCustomer) => {
				data.allPolicies.forEach((policy) => this.#policiesStore.addPublicGroup(policy));
			}),
		);
	}
}
